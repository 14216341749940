import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="tagline">DOWN FOR MAINTENANCE</p>
<h2 className="coming-soon">The Future of Digital Comics Will Be Back Soon</h2>

      </header>
    </div>
  );
}

export default App;
